<template>
    <div class="bind-phone" v-if="login_phone">
        <div class="phone-success">
            <h3>手机号更换成功</h3>
            <span v-if="modfiy_phone">新手机号{{ modfiy_phone }}已生效</span>
        </div>

        <div class="phone-ct-sub">
            <a href="javaScript:;" class="active relogin" @click="onLogout">返回个人中心</a>
        </div>
    </div>
</template>

<script>
import { authChecker } from '@/common/js/mmcModule';
import { logout } from './js/api.js';

export default {
    name: 'bindPhone',
    data () {
        return {
            modfiy_phone: '',
            login_phone: '',
        }
    },

    created() {
        showLoading('加载中...');

        const { phone } = this.$route.query;
        authChecker.check({ notneedphone: 0 }, res => {
            this.login_phone = res.data.attr.phone;
            this.modfiy_phone = phone;
            hideLoading();
        });
    },

    methods: {
        toPhoneModify() {
            this.$router.push({
                path: '/fund/my/phonemodify',
            })
        },

        onLogout() {
            logout().then(res => {
                if (res.code === 0) {
                    location.href = `${window.BasePath}fund/my`;
                } else {
                    alertMsg(res.msg);
                }
            })
        }
    }
}
</script>

<style lang='less' scoped>
.bind-phone {
    padding: 30px 20px;
}

.phone-success {
    text-align:center;
    margin-bottom: 12px;
    h3 {
        margin:0 0 20px;
        font-size: 20px;
        color:#009BFF;
    }
    span {
        font-size: 14px;
        color:#3e3e3e;
    }
}

.phone-ct-sub {
    padding:28px 0;
    height:40px;
    padding-bottom:45px;

    button {
        display:block;
        width:100%;
        height:40px;
        background:#ccc;
        outline:none;
        border:0;
        font-size:16px;
        color:#fff;
        border-radius:40px;
    }

    a {
        display: block;
        width: 100%;
        padding: 15px 0;
        line-height: 1;
        text-align: center;
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 2px 15px 0 rgba(0 155 255, 40%);
        box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
        outline: none;
        border: 0;
        font-size: 16px;
        color:#fff;
        cursor: pointer;
    }
}
</style>
