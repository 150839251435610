
import { mmcAjax } from '@/common/js/mmcModule';

/**
 * 获取短信
 * @param {object} params
 * @param {string} params.phone 手机号
 **/

export function logout(redirect_url) {
    const url = `${window.APIBasePath}mmc/passport/logout`;
    return mmcAjax({
        method: 'POST',
        url,
        crossDomain: true,
        data: {
            redirect_url
        },
    });
}
